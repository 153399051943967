<template>
  <div class="NotFound py-20">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 text-center text-blue">

          <h1>
            You seem lost!
          </h1>
          <div class="mt-4">
            There's nothing here,<br> ride carefully!
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

</style>
